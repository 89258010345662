.react-datepicker {
  font-family: 'Proxima-Nova';
  font-size: 1rem;
  line-height: 17px;
  border: 0;
  width: 100%;
}

.react-datepicker__current-month {
  font-weight: 600;
  font-size: 1rem;
  line-height: 17px;
  padding-bottom: 20px;
  color: var(--color-brown);
}

.react-datepicker__day-name {
  margin: 0.5rem;
  font-weight: 400;
  color: var(--color-primary);
}

.react-datepicker__day {
  margin: 0.5rem;
  outline: none;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
  opacity: 0.4 !important;
  color: var(--color-placeholder);
}

.react-datepicker__header {
  font-weight: 600;
  background: transparent;
  border: 0;
  padding-bottom: 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: var(--color-secondary) !important;
}

.react-datepicker__navigation {
  overflow: inherit;
}

.react-datepicker__navigation-icon {
  width: 100%;
}

.react-datepicker__navigation-icon--previous::before {
  left: 10px;
}

.react-datepicker__navigation-icon--next::before {
  right: 10px;
  left: auto;
}

.react-datepicker .highlight-day {
  box-shadow: 0 0 0 1px var(--color-primary);
  border-radius: 50%;
}
