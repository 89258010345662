[type='checkbox'] {
  color: #85c0af !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 3px !important;
  border-color: #cecece !important;
  cursor: pointer !important;
}

[type='checkbox']:checked {
  color: #85c0af;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border-color: #cecece;
  cursor: pointer;
}

[type='checkbox']:focus {
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 calc(2px + 2px) #85c0af,
    0 0 #0000;
}

[type='checkbox']:disabled {
  color: var(--color-brown-100);
}

.react-datepicker {
  box-shadow: 0px 10px 40px 0px rgba(24, 51, 61, 0.1);
  padding: 16px;
}

@media only screen and (max-device-width: 480px) {
  .react-datepicker {
    box-shadow: none;
    margin-top: 20px;
  }
}

.react-datepicker__navigation-icon {
  top: 12px;
}

.rct-scroll {
  overflow-y: auto !important;
  touch-action: auto !important;
  -webkit-overflow-scrolling: touch;
}
