/*
 * code taken from https://codepen.io/chriscoyier/pen/XWKEVLy
 * changes:
 * - min height is set on .grow-wrap and textarea
 * - overflow: hidden is removed on textarea
 */

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  max-height: 150px;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  /*overflow: hidden;*/

  max-height: 150px;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
