.selectorCheckbox {
  color: #85c0af;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border-color: #cecece;
  cursor: pointer;
}

.selectorCheckbox:focus {
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 calc(2px + 2px) #85c0af,
    0 0 #0000;
}
