@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Proxima-Nova';
    src: url('assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima-Nova';
    src: url('assets/fonts/ProximaNova-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  :root {
    --color-primary: #85c0af;
    --color-light-green: #f3f9f7;
    --color-secondary: #18333d;
    --color-accent: #eb6e60;
    --color-green-100: #c1dfd6;
    --color-dark-green: #163c4b;
    --color-dark-blue: #0d5a76;
    --color-brown-50: #cecece;
    --color-brown-100: #9e9e9e;
    --color-brown-300: #333333;
    --color-brown: #4a4a49;
    --color-red-200: #fbe2df;
    --color-red-300: #eb6e60;
    --color-gray-50: #f9fafb;
    --color-gray-100: #f5f5f5;
    --color-gray-200: #eff2f5;
    --color-gray-250: #dedede;
    --color-gray-300: #8b9da5;
    --color-placeholder: #9e9e9e;
    --color-yellow-300: #ffc93f;
  }
}

@layer utilities {
  .mobile-browser-height {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* https://dev.to/rvxlab/dealing-with-100vh-on-ios-safari-in-tailwindcss-49la */
  @supports (-webkit-touch-callout: none) {
    .mobile-browser-height {
      /* mobile viewport bug fix */
      height: -webkit-fill-available;
    }
  }

  /*
    * leave additional space at the bottom
    * fix for https://app.asana.com/0/1200090235645715/1201738600574657/f
    */
  .mobile-bottom-space {
    width: 100%;
    height: 1px;
    margin-bottom: 30px !important;
  }
}
