.react-datepicker {
  font-family: 'Proxima-Nova';
  font-size: 1rem;
  line-height: 17px;
  border: 0;
  width: 100%;
}

.react-datepicker__current-month {
  font-weight: 600;
  font-size: 1rem;
  line-height: 17px;
  padding-bottom: 20px;
  color: var(--color-brown);
  padding-bottom: 10px;
}

.react-datepicker__day-name {
  --day-square-size: 42px;

  font-weight: 400;
  color: var(--color-primary);
  margin: 0;
  width: var(--day-square-size);
  height: var(--day-square-size);
  padding: 0.5rem;
  line-height: 30px;
}

.react-datepicker__day {
  --day-square-size: 42px;

  outline: none;
  margin: 0;
  width: var(--day-square-size);
  height: var(--day-square-size);
  padding: 0.5rem;
  line-height: 30px;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
  opacity: 0.4 !important;
  color: var(--color-placeholder);
}

.react-datepicker__header {
  font-weight: 600;
  background: transparent;
  border: 0;
  padding-bottom: 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: var(--color-secondary) !important;
}

.react-datepicker__day--in-range {
  border-radius: 0;
  color: black;
  background-color: #18333d33;
}

.react-datepicker__day--in-range:hover {
  border-radius: 0;
  color: white;
  background-color: black;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  position: relative;
  color: white;
  border-radius: 50% !important;
  background-color: #18333d !important;
  z-index: 1;
}

.react-datepicker__day--range-start:hover,
.react-datepicker__day--range-end:hover {
  position: relative;
  color: white;
  border-radius: 50% !important;
  background-color: #18333d !important;
  z-index: 1;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
  position: relative;
  color: white;
  background-color: #18333d !important;
  z-index: 1;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start:hover {
  position: relative;
  color: white;
  background-color: #18333d !important;
  z-index: 1;
  border-radius: 0;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range {
  background-color: #f0f0f0;
  color: black;
  border-radius: 0;
}

.react-datepicker__day:hover.react-datepicker__day--in-selecting-range {
  background-color: #f0f0f0;
  color: black;
  border-radius: 0;
}

.react-datepicker__day--range-start::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: #18333d33;
  color: white;
  z-index: -1;
}
.react-datepicker__day--range-end::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #18333d33;
  z-index: -1;
}

/* In case the range selection is only one day */
.react-datepicker__day--range-start.react-datepicker__day--range-end::before {
  width: 0;
  height: 0;
}

.react-datepicker__navigation {
  overflow: inherit;
}

.react-datepicker__navigation-icon {
  width: 100%;
}

.react-datepicker__navigation-icon--previous::before {
  left: 10px;
}

.react-datepicker__navigation-icon--next::before {
  right: 10px;
  left: auto;
}

.react-datepicker .highlight-day {
  box-shadow: 0 0 0 1px var(--color-primary);
  border-radius: 50%;
}
