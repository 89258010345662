.react-calendar-timeline .rct-header-root {
  background-color: #f9fafb;
  border-bottom: 1px solid #cecece;
  border-top: 1px solid #cecece;
}

.react-calendar-timeline .rct-calendar-header {
  border: 0px;
}

.rct-sidebar-row-odd,
.rct-hl-odd {
  background-color: transparent !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #cecece;
  position: relative;
  z-index: 30;
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid #f5f5f5;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid #cecece;
}

/* Vertical Lines / Columns */
.rct-vl {
  border-color: #f5f5f5;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 2px solid #f5f5f5;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background-color: #f3f9f7;
}
.vertical-line-today {
  background-color: #e7eff2 !important;
}

/* Sticky Column Header */
.rct-header-root {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: transparent;
}
